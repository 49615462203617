import React from 'react';
import styled from "styled-components";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Header from "../components/header";
import Footer from "../components/footer";
import {graphql, Link, useStaticQuery} from "gatsby";
import Decoration from "../images/decoration.svg";
import "../index.css"
import Seo from "../components/seo";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  width: 1110px;
  align-self: center;
  position: relative;
  @media screen and (max-width: 1100px) {
    width: 1000px;
  }
  @media screen and (max-width: 960px) {
    width: 850px;
  }
  @media screen and (max-width: 800px) {
    width: 700px;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-weight: 800;
  font-size: 30px;
  color: #4C77FF;
  margin-bottom: 72px;
  align-self: center;
  width: 1110px;
  @media screen and (max-width: 1100px) {
    width: 1000px;
    font-size: 26px;
    margin-bottom: 33px;
  }
  @media screen and (max-width: 960px) {
    width: 850px;
    font-size: 23px;
    margin-bottom: 27px;
  }
  @media screen and (max-width: 800px) {
    width: 700px;
    font-size: 20px;
    margin-bottom: 22px;
  }
`;

const Subtitle = styled(Tab)`
  font-family: Montserrat !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 29px !important;
  color: #103072 !important;
  padding: 0 0 6px 0 !important;
  margin-right: 40px !important;
  text-transform: unset !important;
`;

const OwnTabs = styled(Tabs)`
  margin-bottom: 72px
`;

const ProjectName = styled.div`
  margin-bottom: 37px;
`;

const Text = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  line-height: 140%;
  color: #212939;
  white-space: break-spaces;
  margin-bottom: 161px;
`;

const OwnLink = styled(Link)`
  text-decoration: none;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 20px;
  color: #212939;
`;

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

const Projects = () => {

    const data = useStaticQuery(query);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const ReturnImages = () => {
        return (
            <>
                <Image src={Decoration} alt="decor"/>
            </>
        )
    }

    const Image = styled.img`
      position: absolute;
      left: -460px;
      top: -60px;
      z-index: -1;
      @media screen and (max-width: 800px) {
        display: none;
      }
    `;

    return (
            <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                <Seo title={data.allStrapiProjectsSeos.nodes[0].seo_title}
                     description={data.allStrapiProjectsSeos.nodes[0].seo_description}
                     keywords={data.allStrapiProjectsSeos.nodes[0].seo_keywords.split(',')}
                />
                <MainContainer>
                    <div style={{alignSelf: "center"}}>
                        <Header/>
                    </div>
                    <Title>
                        Проекты
                    </Title>
                    <Container>
                        <ReturnImages/>
                        <OwnTabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Subtitle label="Завершенные"/>
                            <Subtitle label="Текущие"/>
                        </OwnTabs>
                        <TabPanel value={value} index={0}>
                            {data.allStrapiProjects.edges.map((item, index) => (
                                <ProjectName>
                                    <OwnLink to={'/projects/' + (index + 1)}>
                                        {item.node.title}
                                    </OwnLink>
                                </ProjectName>
                            ))}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Text>
                                {data.allStrapiCurrentProjects.edges[0].node.text}
                            </Text>
                        </TabPanel>
                    </Container>
                </MainContainer>
                <Footer/>
            </div>
    )
}

const query = graphql`
query{
  allStrapiCurrentProjects {
    edges {
      node {
        text
      }
    }
  }
  allStrapiProjects(sort: {fields: created_at}) {
    edges {
      node {
        title
        partner {
          partnerName
          work {
            nameOfWork
            year
          }
          logo {
            localFile {
              url
            }
          }
        }
      }
    }
  }
    allStrapiProjectsSeos {
    nodes {
      seo_title
      seo_keywords
      seo_description
    }
  }
}
`;

export default Projects;